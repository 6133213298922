import { FC, ReactNode, useState } from 'react';
import { Spin, Tooltip, Upload, UploadProps, message } from 'antd';
import { ErrorMessage, Field, FieldInputProps, FormikProps } from 'formik';
import { useTranslation } from 'next-i18next';
import { FILE_BINARY_SIZE, LIST_SUPPORT_FILE, MAX_FILE_SIZE } from '@components/_v2/SecondContactUsForm/constants';

const { Dragger } = Upload;

const CustomUpload: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  props: any;
  value?: any;
  label?: string;
  isRequired?: string;
  placeholder?: string;
  listFileTypeSupport: Array<string>;
  maxSize?: number;
}> = ({
  field,
  form,
  value,
  label,
  isRequired,
  listFileTypeSupport = LIST_SUPPORT_FILE,
  maxSize = MAX_FILE_SIZE,
  placeholder = 'web3.txtAttachAnyDetailedDescription',
  ...props
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const randomId = `${Math.floor(Math.random())}`;

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    onChange(info) {
      try {
        const { status } = info.file;
        if (status !== 'uploading') {
          setLoading(true);
        }
        if (status === 'done') {
          setLoading(false);
          form.setFieldValue(field.name, info.file);
        } else if (status === 'error') {
          setLoading(false);
          message.error(`${info.file.name} file upload failed.`);
        }
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },

    beforeUpload(file) {
      if (!listFileTypeSupport.includes(file?.type)) {
        message.error(
          {
            className: 'error-upload',
            content: t('web3.invalid_type'),
          },
          4,
        );
        return Upload.LIST_IGNORE;
      }

      if (file?.size > maxSize * Math.pow(FILE_BINARY_SIZE, 2)) {
        message.error(
          {
            className: 'error-upload',
            content: t('web3.invalid_size'),
          },
          4,
        );
        return Upload.LIST_IGNORE;
      }

      setLoading(true);
    },
  };

  const handleRemoveFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    form.setFieldValue(field.name, null);
  };

  return (
    <div className="upload-container upload">
      {label && (
        <label htmlFor={randomId} className="label">
          {label}
          {isRequired ? '*' : ` (${t('common.textOptional')})`}
        </label>
      )}
      <Spin spinning={loading}>
        <Dragger
          id={randomId}
          defaultFileList={value ? [{ uid: '-1', name: 'uploaded', status: 'done', url: value }] : []}
          maxCount={1}
          {...field}
          {...props}
          {...uploadProps}
        >
          {placeholder && (
            <div className="placeholder-text">
              <p>{t(placeholder) || t('common.uploadText')}</p>

              {!field.value ? (
                <div className="placeholder-text__container">
                  <p className="upload-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <path
                        d="M6.125 3.75C6.125 2.9212 6.45424 2.12634 7.04029 1.54029C7.62634 0.95424 8.4212 0.625 9.25 0.625C10.0788 0.625 10.8737 0.95424 11.4597 1.54029C12.0458 2.12634 12.375 2.9212 12.375 3.75V15C12.375 15.4973 12.1775 15.9742 11.8258 16.3258C11.4742 16.6775 10.9973 16.875 10.5 16.875C10.0027 16.875 9.52581 16.6775 9.17417 16.3258C8.82254 15.9742 8.625 15.4973 8.625 15V6.25C8.625 6.08424 8.69085 5.92527 8.80806 5.80806C8.92527 5.69085 9.08424 5.625 9.25 5.625C9.41576 5.625 9.57473 5.69085 9.69194 5.80806C9.80915 5.92527 9.875 6.08424 9.875 6.25V15C9.875 15.1658 9.94085 15.3247 10.0581 15.4419C10.1753 15.5592 10.3342 15.625 10.5 15.625C10.6658 15.625 10.8247 15.5592 10.9419 15.4419C11.0592 15.3247 11.125 15.1658 11.125 15V3.75C11.125 3.50377 11.0765 3.25995 10.9823 3.03247C10.888 2.80498 10.7499 2.59828 10.5758 2.42417C10.4017 2.25006 10.195 2.11195 9.96753 2.01773C9.74005 1.9235 9.49623 1.875 9.25 1.875C9.00377 1.875 8.75995 1.9235 8.53247 2.01773C8.30498 2.11195 8.09828 2.25006 7.92417 2.42417C7.75006 2.59828 7.61195 2.80498 7.51773 3.03247C7.4235 3.25995 7.375 3.50377 7.375 3.75V15C7.375 15.8288 7.70424 16.6237 8.29029 17.2097C8.87634 17.7958 9.6712 18.125 10.5 18.125C11.3288 18.125 12.1237 17.7958 12.7097 17.2097C13.2958 16.6237 13.625 15.8288 13.625 15V6.25C13.625 6.08424 13.6908 5.92527 13.8081 5.80806C13.9253 5.69085 14.0842 5.625 14.25 5.625C14.4158 5.625 14.5747 5.69085 14.6919 5.80806C14.8092 5.92527 14.875 6.08424 14.875 6.25V15C14.875 16.1603 14.4141 17.2731 13.5936 18.0936C12.7731 18.9141 11.6603 19.375 10.5 19.375C9.33968 19.375 8.22688 18.9141 7.40641 18.0936C6.58594 17.2731 6.125 16.1603 6.125 15V3.75Z"
                        fill="#0047ab"
                      />
                    </svg>

                    {t('web3.txtUploadFile')}
                  </p>

                  <Tooltip
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('common.you_can_attach_up_to_1_file'),
                        }}
                      />
                    }
                    overlayClassName="contact-upload-file-tooltip"
                    placement="topLeft"
                    arrowPointAtCenter
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <g clipPath="url(#clip0_0_28)">
                        <path
                          d="M8.5 15C6.64348 15 4.86301 14.2625 3.55025 12.9497C2.2375 11.637 1.5 9.85652 1.5 8C1.5 6.14348 2.2375 4.36301 3.55025 3.05025C4.86301 1.7375 6.64348 1 8.5 1C10.3565 1 12.137 1.7375 13.4497 3.05025C14.7625 4.36301 15.5 6.14348 15.5 8C15.5 9.85652 14.7625 11.637 13.4497 12.9497C12.137 14.2625 10.3565 15 8.5 15ZM8.5 16C10.6217 16 12.6566 15.1571 14.1569 13.6569C15.6571 12.1566 16.5 10.1217 16.5 8C16.5 5.87827 15.6571 3.84344 14.1569 2.34315C12.6566 0.842855 10.6217 0 8.5 0C6.37827 0 4.34344 0.842855 2.84315 2.34315C1.34285 3.84344 0.5 5.87827 0.5 8C0.5 10.1217 1.34285 12.1566 2.84315 13.6569C4.34344 15.1571 6.37827 16 8.5 16Z"
                          fill="#666666"
                        />
                        <path
                          d="M5.75415 5.786C5.75278 5.81829 5.75803 5.85053 5.76958 5.88072C5.78113 5.91091 5.79873 5.93841 5.8213 5.96155C5.84388 5.98468 5.87094 6.00296 5.90084 6.01524C5.93073 6.02753 5.96283 6.03357 5.99515 6.033H6.82015C6.95815 6.033 7.06815 5.92 7.08615 5.783C7.17615 5.127 7.62615 4.649 8.42815 4.649C9.11415 4.649 9.74215 4.992 9.74215 5.817C9.74215 6.452 9.36815 6.744 8.77715 7.188C8.10415 7.677 7.57115 8.248 7.60915 9.175L7.61215 9.392C7.6132 9.45761 7.64 9.52017 7.68677 9.5662C7.73354 9.61222 7.79653 9.63801 7.86215 9.638H8.67315C8.73945 9.638 8.80304 9.61166 8.84992 9.56478C8.89681 9.51789 8.92315 9.4543 8.92315 9.388V9.283C8.92315 8.565 9.19615 8.356 9.93315 7.797C10.5421 7.334 11.1771 6.82 11.1771 5.741C11.1771 4.23 9.90115 3.5 8.50415 3.5C7.23715 3.5 5.84915 4.09 5.75415 5.786ZM7.31115 11.549C7.31115 12.082 7.73615 12.476 8.32115 12.476C8.93015 12.476 9.34915 12.082 9.34915 11.549C9.34915 10.997 8.92915 10.609 8.32015 10.609C7.73615 10.609 7.31115 10.997 7.31115 11.549Z"
                          fill="#666666"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_0_28">
                          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Tooltip>
                </div>
              ) : (
                <div className="file-uploaded">
                  <div className="file-uploaded__desc">
                    <div className="file-name">{field.value.name}</div>
                    <div className="file-size">{(field.value.size / (1024 * 1024)).toFixed(2)}MB</div>
                  </div>

                  <img src="/icons/_services/ai/close-icon.svg" className="dragger__icon" onClick={handleRemoveFile} />
                </div>
              )}
            </div>
          )}
        </Dragger>
      </Spin>
    </div>
  );
};

export const FieldUpload: FC<{
  name: string;
  label?: string;
  required?: boolean;
  value?: string;
  accept?: string;
  placeholder?: string;
  children?: ReactNode;
}> = ({ name, label, required = false, value, accept }) => {
  const { t } = useTranslation();

  const validateField = () => {
    if (!value && required) {
      return t('common.txtFieldRequired');
    }
  };

  return (
    <>
      <Field
        name={name}
        label={label}
        component={CustomUpload}
        isRequired={required}
        validate={validateField}
        accept={accept}
      />

      <ErrorMessage name={name} component="div" className="text-error" />
    </>
  );
};
