import { Select } from 'antd';
import { ErrorMessage, Field, FieldInputProps, FormikProps } from 'formik';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
const { Option } = Select;

const SelectOption: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  props: any;
  options?: [
    {
      label: any;
      value: any;
    },
  ];
  isRequired?: boolean;
  hasAllOption?: boolean;
  icon?: any;
  onChange?: any;
  label?: string;
}> = ({
  form,
  field,
  options,
  hasAllOption,
  label,
  onChange,
  isRequired,
  ...props
}) => {
  const { t } = useTranslation();

  const randomId = `${Math.floor(Math.random())}`;

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    } else {
      form.setFieldValue(field.name, value);
    }
  };

  return (
    <div className="select-container">
      {label && (
        <label htmlFor={randomId} className="label">
          {label}
          {isRequired ? '*' : ` (${t('common.textOptional')})`}
        </label>
      )}
      <Select onChange={handleChange} {...props}>
        {hasAllOption && (
          <Option value="" key="">
            {t('career.txtAllCategories')}
          </Option>
        )}
        {options.map((item) => (
          <Option value={item.value} key={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export const FieldSelectOption: FC<{
  name: string;
  required?: boolean;
  options?: any;
  value?: any;
  placeholder?: string;
  label?: string;
  hasAllOption?: boolean;
  suffixIcon?: any;
  onChange?: any;
  filterOption?: any;
  onSearch?: any;
  optionFilterProp?: string;
  showSearch?: boolean;
  isRequired?: boolean;
}> = ({ name, hasAllOption, required = false, options, ...propsField }) => {
  return (
    <>
      <Field
        name={name}
        component={SelectOption}
        isRequired={required}
        options={options}
        hasAllOption={hasAllOption}
        {...propsField}
      />
      <ErrorMessage name={name} component="div" className="text-error" />
    </>
  );
};
