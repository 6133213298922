import { TFunction } from 'next-i18next';

export const DevelopmentBudget = (t: TFunction) => [
  {
    value: 'lessThan10k',
    label: t('contact-us.less_than_10'),
  },
  {
    value: '10k-20k',
    label: t('contact-us.10_to_20'),
  },
  {
    value: '20k-100k',
    label: t('contact-us.20_to_100'),
  },
  {
    value: '100k-500k',
    label: t('contact-us.100_to_500'),
  },
  {
    value: '500k+',
    label: t('contact-us.more_than_500'),
  },
];

export const FILE_BINARY_SIZE = 1024;
export const MAX_FILE_SIZE = 20;
export const LIST_SUPPORT_FILE = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   'audio/mpeg',
  //   'audio/ogg',
  //   'audio/wav',
  //   'audio/webm',
  //   'video/ogg',
  //   'video/mp4',
  //   'video/webm',
  //   '3d/glb',
];

export const SPREADSHEET_ID = process.env.NEXT_PUBLIC_SPREADSHEET_ID;
export const SHEET_ID = process.env.NEXT_PUBLIC_SHEET_ID;
export const GOOGLE_CLIENT_EMAIL = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_EMAIL;
export const GOOGLE_SERVICE_PRIVATE_KEY = process.env.GOOGLE_SERVICE_PRIVATE_KEY;
