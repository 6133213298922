import { contactAPI } from '@apiClient/post-api';
import { FieldTextInput } from '@components/FormItem/Input';
import { FieldSelectOption } from '@components/FormItem/Select';
import { FieldTextArea } from '@components/FormItem/TextArea';
import { FieldUpload } from '@components/FormItem/Upload';
import { activeModalComplete } from '@context/action/modal';
import { Col, Grid, Row } from 'antd';
import 'antd/dist/antd.css';
import { useModalComplete } from 'context/provider/modal';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Element, scroller } from 'react-scroll';
import countries from 'utils/countries.json';
import { object, string } from 'yup';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import {
  DevelopmentBudget,
  GOOGLE_CLIENT_EMAIL,
  GOOGLE_SERVICE_PRIVATE_KEY,
  SHEET_ID,
  SPREADSHEET_ID,
} from './constants';
import { siteUrl } from '@common/constant';
import { formatDateByLocale } from 'utils';

type propsContactUsForm = {
  titleForm: string;
  subtitleForm: string;
  textSendMessage?: string;
  showCountArea?: boolean;
};

const { useBreakpoint } = Grid;

const SecondContactUsFormV2 = (props: propsContactUsForm) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { md } = useBreakpoint();
  const formikRef = useRef(null) as any;
  const { dispatch } = useModalComplete();
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
  const isContactPage = router.pathname === '/contact';

  const { titleForm, subtitleForm, textSendMessage, showCountArea = true } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isBookACall, setIsBookACall] = useState(false);

  const getId = (country) => {
    if (country === 'Japan') return 14331;
    else return 11603;
  };

  const handleSubmit = async (data) => {
    setIsLoading(true);

    const _window = window as any;
    _window.grecaptcha.ready(function () {
      _window.grecaptcha
        .execute(process.env.NEXT_PUBLIC_CAPTCHA_KEY, {
          action: 'contact',
        })
        .then(async function (token) {
          data = {
            ...data,
            url: `${siteUrl}${router.asPath}`,
            _wpcf7_recaptcha_response: token,
            buttonType: isBookACall ? 'Button Book A Call' : 'Button Send Message',
            file: data?.file?.originFileObj,
            Timestamp: formatDateByLocale(new Date(), 'vi'),
          };

          try {
            await doc.useServiceAccountAuth({
              client_email: GOOGLE_CLIENT_EMAIL,
              private_key: GOOGLE_SERVICE_PRIVATE_KEY.replace(/\\n/g, '\n'),
            });
            // loads document properties and worksheets
            await doc.loadInfo();

            const sheet = doc.sheetsById[SHEET_ID];
            await sheet.addRow(data);
          } catch (error) {}

          const formData = new FormData();
          for (const key in data) {
            formData.append(key, data[key]);
          }
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          try {
            contactAPI
              .submitForm(getId(data?.country), formData)
              .then(() => {
                dispatch(activeModalComplete());
                setIsLoading(false);
                formikRef.current.resetForm();
                if (isBookACall) {
                  setIsBookACall(false);

                  if (isSafari) {
                    window.location.assign('https://meetings.hubspot.com/katie-pham');
                  } else {
                    setTimeout(() => {
                      window.open('https://meetings.hubspot.com/katie-pham', '_blank');
                    });
                  }
                }

                if (isBookACall) {
                  router.push(
                    {
                      pathname: router.pathname,
                      query: { step: 'book-call' },
                    },
                    null,
                    { shallow: true },
                  );
                } else {
                  router.push(
                    {
                      pathname: router.pathname,
                      query: { step: 'submit' },
                    },
                    null,
                    { shallow: true },
                  );
                }
              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false);
                setIsBookACall(false);
              });
          } catch (error) {
            console.error(error);
            setIsLoading(false);
            setIsBookACall(false);
          }
        });
    });
  };

  const validationSubscribe = object({
    username: string().required(t('common.txtFieldRequired')).trim(),
    email: string().email(t('common.txtPleaseEnter')).required(t('common.txtFieldRequired')),
    country: string().nullable().required(t('common.txtFieldRequired')),
    company: string().nullable().required(t('common.txtFieldRequired')),
    howKnowUs: string().required(t('common.txtFieldRequired')).trim(),
    budget: string().nullable().required(t('common.txtFieldRequired')),
    inquiries: string().required(t('common.txtFieldRequired')).trim(),
  });

  useEffect(() => {
    if (router?.query?.email) {
      scroller.scrollTo('contact-us-form', {
        duration: 500,
        delay: 100,
        smooth: true,
        offset: -150,
      });
    }
  }, [router?.query?.email]);

  return (
    <Element name="contact-us-form">
      <div className="contact-us-form contact-us-form-v2">
        <div className="contact-us-form__title" dangerouslySetInnerHTML={{ __html: titleForm }} />
        <div className="contact-us-form__description" dangerouslySetInnerHTML={{ __html: subtitleForm }} />
        <Formik
          initialValues={{
            username: '',
            email: router?.query?.email || '',
            phone: '',
            country: null,
            company: '',
            howKnowUs: '',
            budget: '',
            inquiries: '',
            file: null,
          }}
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={validationSubscribe}
        >
          {({ values, handleSubmit }) => (
            <Form id="contact_us_form" className="contact-us-form__form">
              <Row gutter={20}>
                <Col xs={24} md={12}>
                  <FieldTextInput
                    name="username"
                    required
                    value={values.username}
                    placeholder={t('web3.txtYournames')}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldTextInput name="email" required value={values.email} placeholder={t('web3.txtEmailAddress')} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldTextInput name="phone" value={values.phone} placeholder={t('web3.txtWhatsappNumber')} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldSelectOption
                    name="country"
                    value={values.country}
                    showSearch
                    options={countries.map(({ label }) => ({
                      value: label,
                      label,
                    }))}
                    optionFilterProp="children"
                    isRequired
                    filterOption={(input, option) =>
                      (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder={t('web3.txtYourCountries')}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldTextInput name="company" value={values.company} placeholder={t('web3.txtYourCompanyRequire')} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldTextInput name="howKnowUs" required value={values.company} placeholder={t('web3.txtHowdids')} />
                </Col>
                <Col xs={24} md={24}>
                  <FieldSelectOption
                    name="budget"
                    value={values.developmentBudget}
                    showSearch
                    options={DevelopmentBudget(t).map(({ value, label }) => ({
                      value,
                      label,
                    }))}
                    optionFilterProp="children"
                    isRequired
                    filterOption={(input, option) =>
                      (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder={t('web3.txtDevelopmentBudget')}
                  />
                </Col>

                {isContactPage ? (
                  <Row
                    style={{
                      width: '100%',
                      maxWidth: '100%',
                      marginTop: isContactPage && (md ? '20px' : '10px'),
                      height: md ? null : '100%',
                    }}
                    gutter={20}
                    wrap={md ? false : true}
                    align="middle"
                  >
                    <Col xs={24} md={12} style={{ padding: '0 0 0 20px', height: '100%' }}>
                      <FieldTextArea
                        name="inquiries"
                        value={values.howKnowUs}
                        placeholder={t('web3.txtDescribeYourNeeds')}
                        rows={4}
                        maxLength={2000}
                        required
                        showCount={showCountArea}
                      />
                    </Col>
                    <Col xs={24} md={12} style={{ padding: '0 0 0 20px', height: '100%', marginTop: md ? 0 : '10px' }}>
                      <FieldUpload name="file" value={values.file} required={false} />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Col xs={24} md={24} style={{ padding: '0 10px', height: '100%', marginTop: md ? 0 : '10px' }}>
                      <FieldUpload name="file" value={values.file} required={false} />
                    </Col>
                    <Col xs={24} md={24} style={{ padding: '0 10px', height: '100%' }}>
                      <FieldTextArea
                        name="inquiries"
                        value={values.howKnowUs}
                        placeholder={t('web3.txtDescribeYourNeeds')}
                        rows={4}
                        maxLength={2000}
                        required
                        showCount={showCountArea}
                      />
                    </Col>
                  </>
                )}

                <Col xs={24} sm={24}>
                  <div
                    className="label-policy"
                    dangerouslySetInnerHTML={{
                      __html: t('v3_homepage.your_information'),
                    }}
                  />
                </Col>

                <Col xs={24} sm={24} className="button-form">
                  <button
                    id="button_book_a_call"
                    className="button-primary button-submit button-secondary "
                    onClick={() => {
                      handleSubmit();
                      setIsBookACall(true);
                    }}
                    disabled={isLoading}
                  >
                    {t('v3_homepage.book_a_call')}{' '}
                    {isLoading && isBookACall ? (
                      <img src="/images/loading-small-bg-white.gif" alt="Loading" />
                    ) : (
                      <img src="/icons/logo-btn.svg" />
                    )}
                  </button>
                  <button
                    type="submit"
                    id="button_send_message"
                    className="button-submit button-primary"
                    disabled={isLoading}
                  >
                    {isLoading && !isBookACall ? (
                      <img src="/icons/loading-logo-white.gif" alt="Loading" className="icon-loading" />
                    ) : (
                      textSendMessage ?? t('common.textSendMessages')
                    )}
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </Element>
  );
};

export default SecondContactUsFormV2;
