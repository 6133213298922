import { Input } from 'antd';
import { ErrorMessage, Field, FieldInputProps, FormikProps } from 'formik';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

const { TextArea: TextAreaAntd } = Input;

const TextArea: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  props: any;
  value?: any;
  maxLength?: number;
  label?: string;
  isRequired?: string;
}> = ({ field, form, value, maxLength, label, isRequired, ...props }) => {
  const { onChange, onBlur } = props as any;
  const { t } = useTranslation();

  const maxLengthInput = maxLength || 256;

  const randomId = `${Math.floor(Math.random())}`;

  const handleChange = (e: any) => {
    const { value } = e.target;
    if (!onChange) {
      form.setFieldValue(field.name, value);
    } else {
      onChange(e);
    }
  };

  const handleBlur = (e: any) => {
    const { value } = e.target;
    if (!onBlur) {
      form.handleBlur(e);
      form.setFieldValue(field.name, value?.trim());
    } else {
      onBlur(e);
    }
  };

  return (
    <div className="textarea-container">
      {label && (
        <label htmlFor={randomId} className="label">
          {label}
          {isRequired ? '*' : ` (${t('common.textOptional')})`}
        </label>
      )}
      <TextAreaAntd
        id={randomId}
        maxLength={maxLengthInput}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value || field.value}
        {...field}
        {...props}
      />
    </div>
  );
};

export const FieldTextArea: FC<{
  name: string;
  label?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  rows?: number;
  showCount?: boolean;
  disabled?: boolean;
  maxLength?: number;
}> = ({
  name,
  label,
  required = false,
  value,
  showCount = false,
  ...propsField
}) => {
  const { t } = useTranslation();

  const validateField = () => {
    if (!value && required) {
      return t('common.txtFieldRequired');
    }
  };

  return (
    <>
      <Field
        name={name}
        label={label}
        component={TextArea}
        isRequired={required}
        validate={validateField}
        showCount={showCount}
        {...propsField}
      />
      <ErrorMessage name={name} component="div" className="text-error" />
    </>
  );
};
