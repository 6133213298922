import { siteUrl } from '@common/constant';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

const formatDate = (value) => {
  const date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${month} ${day}, ${year}`;
};

const formatDateByLocale = (date, locale) => {
  return dayjs(date).locale(locale).format('MMM DD, YYYY');
};

const getLocation = () => {
  if (typeof window !== 'undefined') {
    return window.location.href;
  }

  return siteUrl;
};

const decodeHtml = (html) => {
  if (!html || typeof document === 'undefined') {
    return;
  }

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

const isEmpty = (obj: {}) => {
  return Object.keys(obj).length === 0;
};

const getSiteUrl = (url: string) => {
  if (!url) {
    return '';
  }

  return url.startsWith('http') ? url : `${siteUrl}${url}`;
};

const getImageAbsoluteUrl = (url) => {
  if (!url) {
    return '';
  }

  return url.startsWith('http') ? url : `${siteUrl}${url}`;
};

const stripHtml = (html) => {
  if (!html || typeof document === 'undefined') {
    return;
  }

  const content = document.createElement('DIV');
  content.innerHTML = html;
  return content.textContent || content.innerText || '';
};

function setCookie(cname: string, cvalue: string | boolean, exdays?: number) {
  if (!exdays) {
    return (document.cookie = cname + '=' + cvalue + ';');
  }
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

const getCookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document?.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const generateGoogleRecaptchaSrc = ({
  useRecaptchaNet,
  useEnterprise,
}: {
  useRecaptchaNet: boolean;
  useEnterprise: boolean;
}) => {
  const hostName = useRecaptchaNet ? 'recaptcha.net' : 'google.com';
  const script = useEnterprise ? 'enterprise.js' : 'api.js';

  return `https://www.${hostName}/recaptcha/${script}`;
};

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

const injectGoogleReCaptchaScript = ({
  siteKey,
  // onLoad,
  useRecaptchaNet,
  useEnterprise,
  scriptProps: { nonce = '', defer = false, async = false, id = '', appendTo },
}) => {
  const scriptId = id || 'google-recaptcha-v3';

  // Script has already been injected, just call onLoad and does othing else
  // if (isScriptInjected(scriptId)) {
  //   onLoad();

  //   return;
  // }

  /**
   * Generate the js script
   */
  const googleRecaptchaSrc = generateGoogleRecaptchaSrc({
    useEnterprise,
    useRecaptchaNet,
  });
  const js = document.createElement('script');
  js.id = scriptId;
  js.src = `${googleRecaptchaSrc}?render=${siteKey}`;

  if (!!nonce) {
    js.nonce = nonce;
  }

  js.defer = !!defer;
  js.async = !!async;
  // js.onload = onLoad;

  /**
   * Append it to the body // head
   */
  const elementToInjectScript = appendTo === 'body' ? document.body : document.getElementsByTagName('head')[0];

  elementToInjectScript.appendChild(js);
};

const cleanGstaticRecaptchaScript = () => {
  const script = document.querySelector('script[src^="https://www.gstatic.com/recaptcha/releases"]');

  if (script) {
    script.remove();
  }
};

const cleanGoogleRecaptcha = (scriptId: string) => {
  (window as any).___grecaptcha_cfg = undefined;

  const script = document.querySelector(`#${scriptId}`);
  if (script) {
    script.remove();
  }

  cleanGstaticRecaptchaScript();
};

const injectHubspotScript = () => {
  const js = document.createElement('script');
  js.id = 'hs-scripts';
  js.src = `//js.hs-scripts.com/6577880.js`;

  js.defer = true;
  js.async = true;

  document.body.appendChild(js);
};

const cleanHubspotScript = () => {
  (window as any).___grecaptcha_cfg = undefined;

  const script = document.querySelector('#hs-scripts');
  if (script) {
    script.remove();
  }
};

const disableLinkEvent =
  (link?: string, clickable = true) =>
  (e: any) => {
    if (!link || !clickable) e.preventDefault();
  };

const groupNumberElementsInArray = (toolsArray, groupSize) => {
  const familiarArray = [];

  for (let i = 0; i < toolsArray.length; i += groupSize) {
    const group = toolsArray.slice(i, i + groupSize);
    familiarArray.push(group);
  }

  return familiarArray;
};

export {
  formatDate,
  getLocation,
  decodeHtml,
  isEmpty,
  getImageAbsoluteUrl,
  stripHtml,
  setCookie,
  getCookie,
  injectGoogleReCaptchaScript,
  cleanGoogleRecaptcha,
  injectHubspotScript,
  cleanHubspotScript,
  formatDateByLocale,
  disableLinkEvent,
  getSiteUrl,
  groupNumberElementsInArray,
};
