import { Input } from 'antd';
import { ErrorMessage, Field, FieldInputProps, FormikProps } from 'formik';
import { FC, ReactNode } from 'react';

const TextInput: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  props: any;
  value?: any;
  maxLength?: number;
  allowClear?: boolean;
  label?: string;
  isRequired?: string;
}> = ({
  field,
  form,
  value,
  maxLength,
  allowClear = true,
  label,
  isRequired,
  ...props
}) => {
  const { onChange, onBlur } = props as any;
  const maxLengthInput = maxLength || 256;
  const randomId = `${Math.floor(Math.random())}`;
  const handleChange = (e: any) => {
    const { value } = e.target;
    if (!onChange) {
      form.setFieldValue(field.name, value);
    } else {
      onChange(e);
    }
  };

  const handleBlur = (e: any) => {
    const { value } = e.target;
    if (!onBlur) {
      form.handleBlur(e);
      form.setFieldValue(field.name, value?.trim());
    } else {
      onBlur(e);
    }
  };

  return (
    <div className="input-container">
      {label && (
        <label htmlFor={randomId} className="label">
          {label}
          {isRequired && '*'}
        </label>
      )}
      <Input
        id={randomId}
        maxLength={maxLengthInput}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value || field.value}
        allowClear={allowClear}
        autoComplete="off"
        {...field}
        {...props}
      />
    </div>
  );
};

export const FieldTextInput: FC<{
  name: string;
  label?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  prefix?: ReactNode;
  className?: string;
  disabled?: boolean;
}> = ({ name, label, required = false, ...propsField }) => {
  return (
    <>
      <Field
        name={name}
        label={label}
        component={TextInput}
        isRequired={required}
        {...propsField}
      />
      <ErrorMessage name={name} component="div" className="text-error" />
    </>
  );
};
