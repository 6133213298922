import axios from 'axios';
import axiosClient from './axios-client';

export const postAPI = {
  getList(payload?: any) {
    return axiosClient.get('/posts', payload);
  },
  getDetails(id, payload?: any) {
    return axiosClient.get(`/posts/${id}`, payload);
  },

  getAuthor(payload: any) {
    return axiosClient.get('/users', payload);
  },

  getAuthorDetail(id: number) {
    return axiosClient.get(`/users/${id}`);
  },
};

export const portfolioAPI = {
  getList(payload?: any) {
    return axiosClient.get('/portfolios', payload);
  },
};

export const categoriesAPI = {
  getList(params?: any, payload?: any) {
    return axiosClient.get('/categories', {
      params: params,
      ...payload,
    });
  },
};

export const contactAPI = {
  submitForm(id: number, payload: any) {
    return axiosClient.post(`https://cms.ekoios.vn/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`, payload);
  },

  saveDataOnSheet(data: any) {
    return axios.post(
      'https://script.google.com/macros/s/AKfycby9iWTH3ugBcIYG9c19NMASgyKUcH9Vlufv8rVba1LbTNNSO9PolQdfIMCuCnfNmqco/exec',
      data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },
  saveCustomerEmailOnSheet(data: any) {
    return axios.post(
      'https://script.google.com/macros/s/AKfycbxzgWEAoaKkPiP3B4gHWptRGWAddU_WKBbJgTUfFIz85AqnPcFQBitpMS2Ze9FhA-7Xnw/exec',
      data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },
  saveDataOnEbookSheet(data: any) {
    console.log('data :>> ', data);
    return axios.post(
      'https://script.google.com/a/macros/ekoios.vn/s/AKfycbxSmBw7VYgbCvhgHMy-Q7DQq_vJSKEtcTliXZRDZtTLNAkdqNqLlgBAbcCzJZ19h3Pc/exec',
      data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },
};

export const searchAPI = {
  getResult(payload?: any) {
    return axiosClient.get('/search', payload);
  },
};

export const serviceAPI = {
  getOurServices(payload?: any) {
    return axiosClient.get('/our-services', payload);
  },
};
export const ebookAPI = {
  getList(payload?: any) {
    return axiosClient.get('/ebooks', payload);
  },

  getEbookDetail(payload?: any) {
    return axiosClient.get('/ebooks', payload);
  },

  getEbookCategory(params?: any) {
    return axiosClient.get('/ebook-category', params);
  },

  submitForm(id: number, payload: any) {
    return axiosClient.post(`https://cms.ekoios.vn/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`, payload);
  },
};
