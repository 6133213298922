export const activeModal = (tab: string) => ({
  type: 'ACTIVE',
  tab,
});

export const deactivateModal = () => ({
  type: 'DEACTIVATE',
});

export const activeModalComplete = () => ({
  type: 'ACTIVE_MODAL_COMPLETE',
});

export const deactivateModalComplete = () => ({
  type: 'DEACTIVATE_MODAL_COMPLETE',
});

export const activeModalVideo = () => ({
  type: 'ACTIVE_MODAL_VIDEO',
});

export const deactivateModalVideo = () => ({
  type: 'DEACTIVATE_MODAL_VIDEO',
});
